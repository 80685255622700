import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/PostLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Vous avez décidé de perdre du poids et vous vous demandez comment faire pour que cela soit durable ? Voici quelques conseils qui pourront vous aider.`}</p>
    <p>{`Tout d'abord, il est important de bien comprendre ce qu'est le poids et comment il est régulé. Le poids est déterminé par la quantité de masse grasse que vous avez dans votre corps. La masse grasse est composée de cellules graisseuses, mais aussi d'eau et de tissus. La graisse est nécessaire au bon fonctionnement de l'organisme, elle est notamment stockée dans le tissu adipeux et permet de réguler la température du corps.`}</p>
    <p>{`Le poids est régulé par un équilibre entre les calories que vous consommez et celles que vous dépensez. Si vous consommez plus de calories que vous n'en dépensez, vous prenez du poids. inversement, si vous dépensez plus de calories que vous n'en consommez, vous perdez du poids.`}</p>
    <p>{`Pour perdre du poids de manière durable, il est donc important de modifier son mode de vie et ses habitudes alimentaires. Voici quelques conseils :`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Mangez moins de calories`}</strong>{` : pour perdre du poids, il faut que vous consommiez moins de calories que vous n'en dépensez. Pour cela, vous pouvez faire attention à ce que vous mangez et faire des choix plus sains. Par exemple, privilégiez les aliments riches en fibres et en protéines, qui sont plus satiétants. limitez les aliments gras, sucrés ou salés, qui sont plus caloriques.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Augmentez votre dépense énergétique`}</strong>{` : pour perdre du poids, il faut que vous dépensiez plus de calories que vous n'en consommez. Pour cela, vous pouvez faire plus d'activité physique. essayez de faire au moins 30 minutes d'activité physique par jour. Vous pouvez aussi faire des choix plus actifs dans votre vie quotidienne, par exemple, prendre les escaliers au lieu de l'ascenseur, etc.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Modifiez vos habitudes alimentaires`}</strong>{` : pour perdre du poids de manière durable, il est important de modifier vos habitudes alimentaires. Par exemple, mangez moins souvent au restaurant, limitez les aliments préparés et les aliments industriels, privilégiez les aliments riches en fibres, en protéines et en vitamines, etc.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Adoptez une attitude positive`}</strong>{` : pour perdre du poids de manière durable, il est important d'avoir une attitude positive. Cela signifie que vous devez vous fixer des objectifs réalisables, être patient, faire preuve de persévérance, etc.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      